/* Disable resizing of the window */
html,
body {
  max-width: 100%;
  overflow: hidden;
  /* prevent scrollers */
  position: fixed;
}

/* Disable moving of the window */
canvas {
  touch-action: none;
  top: 0;
  left: 0;
}

a {
  color: white;    
  text-decoration: none; /* no subrayado */
}



/* Make sure the canvas covers the entire viewport */
canvas {
  width: 100vw;
  /* Set the width to 100% of the viewport width */
  height: 100vh;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: rgba(46, 46, 46, 1);
  margin: 0;
  padding: 0;
}


#form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5;
  margin-bottom: 10px;
}

input {
  padding: 10px;
  margin-bottom: 10px;
  background: rgb(34, 34, 34);
  background: linear-gradient(180deg,
      rgb(25, 25, 25) 0%,
      rgb(30, 30, 30) 35%,
      rgba(34, 34, 34) 100%);
  border-radius: 6px;
  display: inline-block;
  cursor: pointer;
  color: #959595;
  font-family: "Saira", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 10px;
  text-decoration: none;
  border-radius: 8px;
}


/* Responsive adjustments */
/* Para pantallas grandes como Desktop */
@media only screen and (min-width: 1024px) {
  #menu {
    width: 25vw;
    left: 35vw;
    top: 5vh;
  }
}

/* fin para Para pantallas grandes como Desktop */

/* Móviles y pantallas pequeñas */
@media only screen and (max-width: 740px) {

  #progress-container {
    height: 24px !important;
  }

  #preloader {
    width: 70vw !important;
    margin: 0px 0px 0px 24px !important;
  }

  #progress-text {
    font-size: 16px !important;
    font-family: "Saira", sans-serif !important;
    margin: 10px 50px 20px 0px !important;
  }

  #progress-container {
    margin: 0 10px !important;
  }

  .intro-bg-1 {
    max-width: 300px !important;
  }

  #logo-intro {
    max-width: 40px !important;
    height: auto !important;
  }

  #texto-intro {
    font-family: "Saira", sans-serif;
    font-size: 19px;
    margin: 10px 5% 10px 5%;
  }

  .oculto {
    display: none;
  }

  .marcas {
    margin-left: 30px;
  }

}

/* fin ajustes Móviles y pantallas pequeñas */


#play-button {
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444 5%, #333 100%);
  background-color: #5d5d5d;
  border: 1px solid #282828;
  display: inline-block;
  cursor: pointer;
  color: #959595;
  font-family: "Saira", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 10px;
  text-decoration: none;
  text-shadow: 0px -1px 0px #181818;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 1px;
}

#play-button:hover {
  background: linear-gradient(to bottom, #222 5%, #444 100%);
  background-color: #7b7b7b;
  color: #767676;
}


#painting-popup {
  display: none;
  position: fixed;
  top: 5%;
  left: 5%;
  background-color: rgba(47, 47, 47, 0.7);
  padding: 20px;
  border-radius: 10px;
  font-family: "Saira", sans-serif;
  color: #cccccc;
  width: 300px;
  max-width: 80%;
  /* Asegura que no se extienda más allá del viewport */
  max-height: 80%;
  /* Asegura que no crezca demasiado en altura */
  overflow-y: auto;
  /* Agrega scroll si el contenido es demasiado grande */
  z-index: 1000;
}

#painting-popup.show {
  display: block !important;
}

#painting-popup h3 {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
}

#form-container h3 {
  font-weight: 400;
  margin-bottom: 4px;
  font-size: 13px;

  margin-bottom: 10px;
}

#popup-description {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #cccccc;
}

#popup-txt-1 {
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 600;
  color: white;
}

#popup-txt-2 {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(176, 176, 176);
}

#popup-divider {
  border-top: 3px dotted #666666;
  margin-top: 10px;
  margin-bottom: 10px;
}

#popup-song {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: normal;
}

#books-popup {
  display: none;
  position: fixed;
  top: 5%;
  left: 5%;
  background-color: rgba(47, 47, 47, 0.7);
  padding: 20px;
  border-radius: 10px;
  font-family: "Saira", sans-serif;
  color: #cccccc;
  width: 300px;
  max-width: 80%;
  /* Asegura que no se extienda más allá del viewport */
  max-height: 80%;
  /* Asegura que no crezca demasiado en altura */
  overflow-y: auto;
  /* Agrega scroll si el contenido es demasiado grande */
  z-index: 1000;
}

#completed-message {
  text-align: center;
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444444ae 30%, #171717ae 100%);
  background-color: #353535;
  position: absolute;
  top: 60%;
  /* Centrar verticalmente */
  left: 50%;
  /* Centrar horizontalmente */
  transform: translate(-50%, -50%);
  /* Asegurar que se mantenga centrado */
  justify-content: center;
  align-items: center;
  padding: 18px;
  border-radius: 10px;
  border: 1px solid #282828;
  font-family: "Saira", sans-serif;
  text-shadow: 0px -1px 0px #181818;
  color: #efefef;
  font-size: 18px;
  z-index: 20;
  display: none;
  /* Empezar invisible */
}

#books-popup-title {
  text-align: center;
  font-family: "Saira", sans-serif !important;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

#books-popup ul {
  list-style-type: disc;
  padding-left: 10px;
  margin-left: 10px;
}


#joystick {
  z-index: 1000;
  pointer-events: auto;
  /* que pueda recibir eventos de puntero */
}

#ingresar {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#intro {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-size: 0.7em 0.7em;
  opacity: 0; /* Inicia invisible */
  visibility: hidden; /* Oculto hasta la animación */
}


#key-counter {
  opacity: 0;
  position: absolute;
  font-family: "Saira", sans-serif;
  color: #cccccc;
  top: 10px;
  left: 10px;
  font-size: 20px;
  font-weight: 500;
  z-index: 10;
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444444ae 30%, #171717ae 100%);
  background-color: #353535;
  border: 1px solid #282828;
  text-align: center;
  border-radius: 10px;
  max-width: 370px;
  margin: 10px;
  padding: 10px 20px 10px 20px;

}


#instrucciones {
  display: none;
  /* Oculto por defecto */
  font-family: "Saira", sans-serif;
  color: #cccccc;
  opacity: 0;
  /* También puedes definir la opacidad inicialmente en 0 */
  /* Suavizar la aparición */
}


#separator {
  background-color: #ff8800;
  width: 100vw;
  /* Set the width to 100% of the viewport width */
  height: 100vh;
  z-index: 12980809;

}

.intro-bg-1 {
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444444ae 30%, #171717ae 100%);
  background-color: #353535;
  border: 1px solid #282828;
  text-align: center;
  border-radius: 20px;
  max-width: 450px;
}

#preloader {
  text-align: center;
  transform: translate(-50%);
  position: relative;
  margin-bottom: 20px;
  left: 50%;
  width: 30vw;
  /* Set the width to 100% of the viewport width */
  background-color: rgba(46, 46, 46, 0);
  z-index: 3;
}

#progress-container {
  display: flex;
  align-items: center;
  /* Center vertically */
  height: 25px;
  margin: 0 20%;
  background: rgb(34, 34, 34);
  background: linear-gradient(180deg,
      rgb(25, 25, 25) 0%,
      rgb(30, 30, 30) 35%,
      rgba(34, 34, 34) 100%);
  border-radius: 6px;
  margin-bottom: 12px;
}

#progress-bar {
  height: 86%;
  width: 0%;
  background: linear-gradient(0deg,
      rgb(40, 40, 40) 0%,
      rgb(55, 55, 55) 50%,
      rgb(40, 40, 40) 100%);
  border-radius: 5px;
  margin-top: 4px;
  margin-bottom: 5px;
  margin-left: 2px;
  margin-right: 1px;
}

#progress-text {
  color: rgba(125, 125, 125, 0.4);
  font-size: 16px;
  font-family: "Saira", sans-serif !important;
  font-weight: 500;
  background-color: rgba(46, 46, 46, 0);
}

#logo-intro {
  max-width: 170px;
  height: auto;
  margin-top: 30px;
}

#texto-intro {
  font-family: "Saira", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #959595;
  text-align: center;
  margin: 15px 10% 15px 10%;
}

.texto-intro-sm {
  font-family: "Saira", sans-serif;
  font-size: 20px;
  color: #6d6d6d;
  text-align: center;
  margin: 20px 10% 5px 10%;
}

#texto-instrucciones {
  font-family: "Saira", sans-serif;
  font-size: 14px;
  color: #cccccc;
  text-align: center;
  margin: 5px 10% 15px 10%;
}

#intro .myButton {
  text-align: center;
}

.myButton {
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444 5%, #333 100%);
  background-color: #5d5d5d;
  border: 1px solid #282828;
  display: inline-block;
  cursor: pointer;
  color: #959595;
  font-family: "Saira", sans-serif !important;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 600;
  padding: 8px 20px;
  text-decoration: none;
  text-shadow: 0px -1px 0px #181818;
  border-radius: 8px;
  margin-bottom: 25px;
}

.myButton:hover {
  background: linear-gradient(to bottom, #222 5%, #444 100%);
  background-color: #7b7b7b;
  color: #767676;
}

.myButton:active {
  position: relative;
  top: 1px;
}

#container {
  width: 100%;
  height: 100%;
}

#tip1 {
  text-align: center;
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444444ae 30%, #171717ae 100%);
  background-color: #353535;
  position: absolute;
  top: 60%;
  /* Centrar verticalmente */
  left: 50%;
  /* Centrar horizontalmente */
  transform: translate(-50%, -50%);
  /* Asegurar que se mantenga centrado */
  justify-content: center;
  align-items: center;
  padding: 18px;
  border-radius: 10px;
  border: 1px solid #282828;
  font-family: "Saira", sans-serif;
  text-shadow: 0px -1px 0px #181818;
  color: #cccccc;
  font-size: 18px;
  z-index: 20;
  opacity: 0;
  /* Empezar invisible */
}

#tip2 {
  text-align: center;
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444444ae 30%, #171717ae 100%);
  background-color: #353535;
  position: absolute;
  top: 60%;
  /* Centrar verticalmente */
  left: 50%;
  /* Centrar horizontalmente */
  transform: translate(-50%, -50%);
  /* Asegurar que se mantenga centrado */
  justify-content: center;
  align-items: center;
  padding: 18px;
  border-radius: 10px;
  border: 1px solid #282828;
  font-family: "Saira", sans-serif;
  text-shadow: 0px -1px 0px #181818;
  color: #cccccc;
  font-size: 18px;
  z-index: 20;
  opacity: 0;
  /* Empezar invisible */
}


/* Estilos joystick */
#joystick-circle {
  position: absolute;
  bottom: 45px;
  width: 80px;
  height: 80px;
  background: url('../static/images/joy1.png') center no-repeat;
  background-size: cover;
  border: #fff solid 0px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

#joystick-thumb {
  position: absolute;
  left: 27.5px;
  /* Cálculo para centrar el pulgar en el círculo */
  top: 27.5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(224, 224, 224, 1) 0%, rgba(141, 141, 141, 1) 50%, rgba(224, 224, 224, 1) 100%);

  /* Sombra para efecto 3D */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

/* Media queries para responsive design */

/* Para pantallas pequeñas (dispositivos móviles) */
@media (max-width: 768px) {
  #joystick-circle {
    width: 120px;
    height: 120px;
    bottom: 40px;
  }

  #texto-instrucciones {
    font-size: 18px;
  }

  #joystick-thumb {
    left: 42.5px;
    /* Cálculo centrado: (120px / 2) - (20px / 2) */
    top: 42.5px;
    /* Cálculo similar para centrar en altura */
    width: 35px;
    height: 35px;
  }

  #logo-intro {
    width: 45% !important;
    height: auto !important;
  }

  #progress-bar {
    height: 86% !important;
  }

  #progress-container {
    height: 24px !important;
  }

  #preloader {
    width: 20vw !important;
    margin: 0px 0px 0px 0px !important;
  }

  #progress-text {
    font-size: 16px !important;
    font-family: "Bebas Neue", sans-serif !important;
    text-align: center;
    margin: 10px 10px 10px 10px !important;
  }

  #progress-container {
    margin: 0 10px !important;
  }

  .intro-bg-1 {
    max-width: 300px !important;
  }



  .oculto {
    display: none;
  }




}

/* fin mobile  */


/* barras scrollers thin y tambien medidas de popups, fonts etc  */
/* Para pantallas grandes (escritorio) */
@media (min-width: 769px) {
  #joystick-circle {
    width: 100px;
    height: 100px;
    bottom: 40px;
  }

  #joystick-thumb {
    left: 35px;
    /* Cálculo centrado: (100px / 2) - (30px / 2) */
    top: 35px;
    /* Cálculo similar para centrar en altura */
    width: 30px;
    height: 30px;
  }
}

/* Custom scrollbar styles */
#painting-popup,
#books-popup {
  /* Scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #efefef;
}

/* Para navegadores basados en Webkit (Chrome, Safari) */
#painting-popup::-webkit-scrollbar,
#books-popup::-webkit-scrollbar {
  width: 6px;
}

#painting-popup::-webkit-scrollbar-track,
#books-popup::-webkit-scrollbar-track {
  background: transparent;
}

#painting-popup::-webkit-scrollbar-thumb,
#books-popup::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.7);
  border-radius: 10px;
  border: 2px solid transparent;
}

#painting-popup::-webkit-scrollbar-thumb:hover,
#books-popup::-webkit-scrollbar-thumb:hover {
  background-color: rgba(150, 150, 150, 0.9);
}

/* Ajustes adicionales para mobile */
@media only screen and (max-width: 740px) {

  #painting-popup,
  #books-popup {
    width: 90%;
    /* Asegura que ocupen más espacio en pantallas pequeñas */
    max-height: 70%;
  }

  /* Texto responsive en el popup */
  #painting-popup p,
  #painting-popup h3,
  #books-popup p,
  #books-popup h2 {
    font-size: 14px;
    /* Ajusta el tamaño de la fuente en móviles */
  }
}